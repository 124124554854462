import React, { Component } from "react";
import { useTranslation } from 'react-i18next';

const Services = () => {
    
    const { t } = useTranslation();
    return (

        <section className="services-section spad">
        <div className="container fade-in">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title">
                        <span>{t('services.what')}</span>
                        <h2>{t('services.top')}</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-sm-6">
                    <div className="service-item">
                        <i className="flaticon-043-room-service"></i>
                        <h4>{t('services.roomservice')}</h4>
                        <p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="service-item">
                        <i className="flaticon-003-air-conditioner"></i>
                        <h4>{t('services.aircon')}</h4>
                        <p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="service-item">
                        <i className="flaticon-029-wifi"></i>
                        <h4>{t('services.wifi')}</h4>
                        <p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="service-item">
                        <i className="flaticon-036-parking"></i>
                        <h4>{t('services.parking')}</h4>
                        <p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="service-item">
                        <i className="flaticon-002-breakfast"></i>
                        <h4>{t('services.breakfast')}</h4>
                        <p></p>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                    <div className="service-item">
                        <i className="flaticon-018-iron"></i>
                        <h4>{t('services.iron')}</h4>
                        <p></p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    )

}

export default Services;