import React from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
  <div>
    <footer className="footer-section">
      <div className="container">
        <div className="footer-text fade-in">
          <div className="row">
            <div className="col-lg-4">
              <div className="ft-contact fade-in">
                <h6>{t("footer.found")}</h6>
                <div className="ft-about">
                  <div className="fa-social">
                    <a target="_blank"
                      rel="noopener noreferrer" href="https://www.facebook.com/rafkonapartmentslxs/">
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a target="_blank"
                      rel="noopener noreferrer" href="https://www.instagram.com/rafkon_apartments/">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ft-contact fade-in">
                <h6> {t("footer.contact")}</h6>
                <ul>
                  <li>
                    <a href="tel:+302254026424">+30 2254026424</a>
                  </li>
                  <li>
                    <a href="mailto:rafkonoe@gmail.com">
                    rafkonoe@gmail.com
                    </a>
                  </li>
                  <li>{t("footer.address")}</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ft-contact fade-in">
                <h6>{t("footer.useful")}</h6>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.astronlemnos.gr/"
                      className="bk-btn"
                    >
                      {t("footer.hotel")}
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.aristearentacar.gr/"
                      className="bk-btn"
                    >
                      {t("footer.rent")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>Powered By Hotel Astron 2023 - {(new Date().getFullYear())}</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
  )
};
export default Footer;
