import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="header-section">
      <div className="top-nav">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <ul className="tn-left">
                <li>
                  <i className="fa fa-phone"></i>
                  <a href="tel:+302254026424">+30 2254026424</a>
                </li>
                <li>
                  <i className="fa fa-envelope"></i>
                  <a href="mailto:rafkonoe@gmail.com">rafkonoe@gmail.com</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="tn-right">
                <div className="top-social">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/rafkonapartmentslxs/"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/rafkon_apartments/"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.booking.com/hotel/gr/rafkon-myrina-apartments.el.html"
                  className="bk-btn"
                >
                  {t("header.booking")}
                </a>
                <div className="language-option">
                  <img src={`./icons/${language}.png`} alt="" />
                  <span>
                    {language.toUpperCase()}{" "}
                    <i className="fa fa-angle-down"></i>
                  </span>
                  <div className="flag-dropdown">
                    <ul>
                      <li>
                        <button style={{ borderStyle: "none", backgroundColor: "transparent" }} onClick={() => changeLanguage(language === "en" ? "gr" : "en")}>
                          {language === "en" ? "GR" : "EN"}<span className="spanLanguage"></span>
                        <img
                          src={`./icons/${language === "en" ? "gr" : "en"}.png`}
                          alt=""
                        />
                        </button>
                        
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="menu-item">
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <div className="logo">
                <Link to="/">
                  <img src="./logo.png" alt="RafKon Logo" />
                </Link>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="nav-menu">
                <nav className="mainmenu">
                  <ul>
                    <li>
                      <Link to="/">{t("header.home")}</Link>
                    </li>
                    <li>
                      <Link to="/about">{t("header.about")}</Link>
                    </li>
                    <li>
                      <Link to="/rooms">{t("header.rooms")}</Link>
                    </li>
                    <li>
                      <Link to="/gallery">{t("header.gallery")}</Link>
                    </li>
                    <li>
                      <Link to="/contact">{t("header.contact")}</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
