import React from "react";

function Map() {
  return (
    <div className="fade-in" id="map">
      <iframe
        width="100%"
        height="600"
        style="border:0;"
        loading="lazy"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12246.361191196262!2d25.0669913!3d39.8834142!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14af85253ac30123%3A0x376f37489d5c9e57!2sRAFKON%20Apartments!5e0!3m2!1sel!2sgr!4v1688225952832!5m2!1sel!2sgr"
        style={{ border: "0" }}
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default Map;
