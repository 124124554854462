import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../components/Header/Header.css";
import { useTranslation } from "react-i18next";


function OverlayMenu() {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className={`offcanvas-menu-overlay ${isOpen ? "active" : ""}`}></div>
      <div onClick={toggleSidebar} className="canvas-open">
        <i className="icon_menu"></i>
      </div>
      <div
        className={`offcanvas-menu-wrapper ${
          isOpen ? "show-offcanvas-menu-wrapper" : ""
        }`}
      >
        <div className="canvas-close" onClick={closeSidebar}>
          <i className="icon_close"></i>
        </div>
        <div className="header-configure-area">
          <div className="language-option">
            <img src={`./icons/${language}.png`} alt="" />
            <span>
              {language.toUpperCase()} <i className="fa fa-angle-down"></i>
            </span>
            <div className="flag-dropdown">
              <ul>
                <li>
                  <button
                    style={{ borderStyle: "none", backgroundColor: "transparent" }}
                    onClick={() =>
                      changeLanguage(language === "en" ? "gr" : "en")
                    }
                  >
                    {language === "en" ? "GR" : "EN"}
                    <span className="spanLanguage"></span>
                    <img
                      src={`./icons/${language === "en" ? "gr" : "en"}.png`}
                      alt=""
                    />
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.booking.com/hotel/gr/rafkon-myrina-apartments.el.html"
            className="bk-btn"
          >
            {t("header.booking")}
          </a>
        </div>
        <nav className="slicknav_menu">
          <ul>
            <li>
              <Link to="/">{t("header.home")}</Link>
            </li>
            <li>
              <Link to="/about">{t("header.about")}</Link>
            </li>
            <li>
              <Link to="/rooms">{t("header.rooms")}</Link>
            </li>
            <li>
              <Link to="/gallery">{t("header.gallery")}</Link>
            </li>
            <li>
              <Link to="/contact">{t("header.contact")}</Link>
            </li>
          </ul>
        </nav>
        <div className="top-social">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/rafkonapartmentslxs/"
          >
            <i className="fa fa-facebook"></i>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/rafkon_apartments/"
          >
            <i className="fa fa-instagram"></i>
          </a>
        </div>
        <ul id="info-overlay" className="top-widget">
          <li>
            <i className="fa fa-phone"></i>
            <a href="tel:+302254026424">+30 2254026424</a>
          </li>
          <li>
            <i className="fa fa-envelope"></i>
            <a href="mailto:rafkonoe@gmail.com">rafkonoe@gmail.com</a>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default OverlayMenu;
