import React from "react";

const VideoPlayer = () => { 
    return (
        <section className="services-section spad">
            
                <video id="video" controls preload="metadata" autoPlay muted loop width="100%" height="100%" controlsList="nodownload">
                    <source src="./video/rafkon.mp4" type="video/mp4" />
                    <track label="English" kind="captions" srcLang="en" />
                </video>
           
        </section>
    );
}

export default VideoPlayer;
