import AboutSort from "components/AboutSort";
import Services from "components/Services";
import SimpleSlider from "components/SimpleSlider";
import VideoPlayer from "components/VideoPlayer";
import React from "react";
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="hero-section">
        <SimpleSlider />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="hero-text fade-in">
                <h1>{t('headingHome')}</h1>
                <p>{t('welcome')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AboutSort />
      <VideoPlayer/>
      <Services />
    </>
  );
};

export default Home;
