import React, { Component } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';

export default class SimpleSlider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    // Simulating 2 seconds loading time
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 100);
  }

  handleImageLoad = () => {
    // Check if all images have loaded
    const allImagesLoaded = document.querySelectorAll('.img').length === this.props.images.length;

    if (allImagesLoaded) {
      // All images have loaded
      this.setState({ isLoading: false });
    }
  };

  render() {

    const settings = {
      loop: true,
      margin: 0,
      items: 1,
      dots: true,
      animateOut: 'fadeOut',
      animateIn: 'fadeIn',
      smartSpeed: 1200,
      autoHeight: false,
      autoplay: true,
      mouseDrag: true
    };

    const imageUrls = [
      './img/slider/SLIDER 1.jpg',
      './img/slider/SLIDER 2.jpg',
      './img/slider/SLIDER 3.jpg',
      './img/slider/SLIDER 5.jpg',
      './img/slider/SLIDER 6.jpg',
      // Add more image URLs as needed
    ];

    return (
      <OwlCarousel className="owl-theme" dots {...settings}>
        {imageUrls.map((imageUrl, index) => (
         
            <div>
              <img style={{ maxHeight: "1200px" }} className="img" src={imageUrl} alt={`Rafkon Slider ${index}`} />
            </div>
          
        ))}
      </OwlCarousel>
    );
  }
}
