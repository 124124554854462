import React from "react";
import "./Contact.css";
import Map from "../sections/Map";
import ContactForm from "../sections/ContactForm";

const Contact = () => {
  return (
      <section className="contact-section spad">
        <div className="container">
         <ContactForm/>
        <Map/>
        </div>
      </section>
  );
};

export default Contact;
