import React, { useState, useEffect } from 'react';
import Loader from './components/Loader';
import { Provider } from 'react-redux';
import Header from "./components/Header/Header";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { I18nextProvider, useTranslation } from 'react-i18next';
import store from "./redux/store";
import "./App.css";
import "./static/css/bootstrap.min.css";
import "./static/css/font-awesome.min.css";
import "./static/css/elegant-icons.css";
import "./static/css/flaticon.css";
import "./static/css/style.css";

import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import Rooms from "./pages/Rooms";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import NotFound from "./pages/404";
import OverlayMenu from "./sections/OverlayMenu";

import i18n from './redux/i18n'; // Import the i18n configuration

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate an asynchronous operation, such as fetching data
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);


  return (
    <div className="App">
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          {isLoading ? (
            <div className="loader-container">
              <Loader color="#123abc" loading={true} size={150} />
            </div>
          ) : (
            <Router>
              <OverlayMenu />
              <Header />
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/rooms" element={<Rooms />} />
                <Route exact path="/gallery" element={<Gallery />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route path="/*" element={<NotFound />} />
              </Routes>
              <Footer />
            </Router>
          )}
        </Provider>
      </I18nextProvider>
    </div>
  );
}

export default App;
