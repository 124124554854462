import React, { Component } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const AboutSort = () => { 
    const { t } = useTranslation();
        return (

            <section className="aboutus-section spad fade-in">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="about-text fade-in">
                                <div className="section-title">
                                    <span>{t('header.about')}</span>
                                    <h2>{t('headingHome')}</h2>
                                </div>
                                <p className="f-para">{t('about.p6')}</p>
                                <p className="f-para">{t('about.p7')}</p>
                                <p className="f-para">{t('about.p8')}</p>
                                <Link to="/about" className="primary-btn about-btn">{t('about.more')}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        )

}

export default AboutSort;