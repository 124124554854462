import React from 'react';
import { RingLoader } from 'react-spinners';

const Loader = () => {
    return (
        <div className="loader-container">
                <RingLoader color={["#dfa974"]} loading={true} size={150} />
            <img src={'./android-chrome-192x192.png'} alt='Loader Logo Rafkon' />
        </div>
    );
};

export default Loader;
