import React from "react";
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  return (
    <section class="aboutus-page-section spad">
      <div class="container">
        <div class="about-page-text">
          <div class="row">
            <div class="col-lg-12">
              <div class="ap-title fade-in">
                <h2></h2>
                <p>{t('about.p1')}</p><p>{t('about.p2')}</p><p>{t('about.p3')}</p><p>{t('about.p4')}</p><p>{t('about.p5')}</p>
              </div>
            </div>
            {/* <div class="col-lg-5">
              <ul class="ap-services">
                <li><i class="icon_check"></i> {t('about.check1')}</li>
                <li><i class="icon_check"></i> {t('about.check2')}</li>
                <li><i class="icon_check"></i> {t('about.check3')}</li>
                <li><i class="icon_check"></i> {t('about.check4')}</li>
                <li><i class="icon_check"></i> {t('about.check5')}</li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
