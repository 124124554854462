import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

const ContactForm = ({ environment, dispatch }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isVerified, setIsVerified] = useState(true);
  const [responseMessage, setResponseMessage] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSurnameChange = (event) => {
    setSurname(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleVerification = (value) => {
    setIsVerified(value ? true : false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate form fields
    if (
      name.trim() === "" ||
      surname.trim() === "" ||
      email.trim() === "" ||
      message.trim() === "" ||
      phone.trim() === "" ||
      subject.trim() === ""
    ) {
      alert(t("contact.alert1"));
      return;
    }

    if (!isVerified) {
      alert(t("contact.alert2"));
      return;
    }

    // Prepare the form data
    const formData = {
      name: name,
      surname: surname,
      email: email,
      phone: phone,
      subject: subject,
      message: message,
    };

    // Get the environment configuration
    // Get the API endpoint based on the environment
    const apiEndpoint =
      environment === "production"
        ? "https://info.rafkonapartments.gr/email/send/rafkon"
        : "http://localhost:8001/email/send/rafkon";

    if (!apiEndpoint) {
      console.error("Invalid environment configuration.");
      return;
    }

    // Make the API request
    fetch(apiEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Basic " + btoa('superUser:mySecretCombinationItsAMysteryForYou')
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("API response:", data);
        if (data.code === 0) {
          setResponseMessage(t("contact.success"));
          // Additional actions for success response
        } else if (data.code === 400) {
          setResponseMessage(t("contact.warning"));
          // Additional actions for error response
        } else {
          setResponseMessage(t("contact.alert3"));
        }
      })
      .catch((error) => {
        console.error("API error:", error);
        // Handle the API error
        setResponseMessage(t("contact.alert3"));
      });
  };

  const responseMessageClass = classnames({
    "alert": true,
    "alert-success": responseMessage === t("contact.success"),
    "alert-warning": responseMessage === t("contact.warning"),
    "alert-danger": responseMessage === t("contact.alert3"),
  });

  return (
    <div style={{ marginBottom: "35px" }} className="row">
      <div className="col-lg-4">
        <div className="contact-text fade-in">
          <h2>{t("contact.info")}</h2>
          <p>{t("contact.desc")}</p>
          <table>
            <tbody>
              <tr>
                <td className="c-o">{t("contact.addressName")}</td>
                <td>{t("contact.address")}</td>
              </tr>
              <tr>
                <td className="c-o">{t("contact.phone")}</td>
                <td>
                  <a href="tel:+302254026424">+30 2254026424</a>
                </td>
              </tr>
              <tr>
                <td className="c-o">Email:</td>
                <td>
                  <a href="mailto:rafkonoe@gmail.com">rafkonoe@gmail.com</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-lg-7 offset-lg-1 fade-in">
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6">
              <input
                type="text"
                id="name"
                placeholder={t("contact.name")}
                value={name}
                onChange={handleNameChange}
              />
            </div>
            <div className="col-lg-6">
              <input
                type="text"
                id="surname"
                placeholder={t("contact.surname")}
                value={surname}
                onChange={handleSurnameChange}
              />
            </div>
            <div className="col-lg-6">
              <input
                type="email"
                id="email"
                value={email}
                placeholder="Email"
                onChange={handleEmailChange}
              />
            </div>
            <div className="col-lg-6">
              <input
                type="text"
                id="phone"
                value={phone}
                placeholder={t("contact.phone")}
                pattern="\d*"
                maxLength="10"
                onChange={handlePhoneChange}
              />
            </div>
            <div className="col-lg-6">
              <select
                name="subject"
                type="text"
                id="subject"
                onChange={handleSubjectChange}
              >
                <option>{t("contact.subject")}</option>
                <option value={t("contact.checkRoom")}>
                {t("contact.checkRoom")}
                </option>
                <option value={t("contact.ask")}>{t("contact.ask")}</option>
                <option value={t("contact.other")}>{t("contact.other")}</option>
              </select>
            </div>
            <div className="col-lg-12">
              <textarea
                id="message"
                placeholder={t("contact.message")}
                value={message}
                onChange={handleMessageChange}
              ></textarea>
            </div>
            <div className="col-lg-12">
              {/* <div className="col-lg-6">
                <ReCAPTCHA
                  sitekey="6LePqe8mAAAAAL9UA-TW36iiyHYPBanvb89Rqrcu"
                  onChange={handleVerification}
                />
              </div> */}
              <div className="col-lg-6">
                <button type="submit">{t("contact.submit")}</button>
              </div>
              <div>
              {responseMessage && <div className={responseMessageClass}>{responseMessage}</div>}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  environment: state.environment.environment,
});


export default connect(mapStateToProps)(ContactForm);
