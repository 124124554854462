import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Rooms = () => {
    const { t } = useTranslation();
    const [modalImage, setModalImage] = useState('');

    const handleImageClick = (imageURL) => {
        setModalImage(imageURL);
        // Show the Bootstrap modal
        window.$('#imageModal').modal('show');
    };


    return (
        <section className="rooms-section spad fade-in">
            <div className="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div className="room-item">
                        <h4>{t('rooms.sup')}</h4>
                        <h3><span></span></h3>
                            <img src="./img/rooms/ΦΩΤΟ ΓΙΑ ΔΩΜΑΤΙΟ 4 ΑΤΟΜΩΝ.jpg" alt="Rafkon Superior Appartment" onClick={() =>
                                handleImageClick(
                                    './img/rooms/full/ΦΩΤΟ ΓΙΑ ΔΩΜΑΤΙΟ 4 ΑΤΟΜΩΝ.jpg'
                                )
                            } />
                            <div class="ri-text">
                                
                                
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td class="r-o">{t('rooms.size')}</td>
                                            <td>40 {t('rooms.sizedesc')}</td>
                                        </tr>

                                        <tr>
                                            <td class="r-o">{t('rooms.capacity')}</td>
                                            <td>{t('rooms.capacitydesc1')}</td>
                                        </tr>

                                        <tr>
                                            <td class="r-o">{t('rooms.beds')}</td>
                                            <td>{t('rooms.bedsdesc')}</td>
                                        </tr>

                                        <tr>
                                            <td class="r-o">{t('rooms.services')}</td>
                                            <td>{t('rooms.servicesdesc')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <a target="_blank"
                                    rel="noopener noreferrer" href="https://www.booking.com/hotel/gr/rafkon-myrina-apartments.en-gb.html?lang=en-gb&soz=1&lang_changed=1#room_1026666601" class="primary-btn">{t('rooms.more')}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div className="room-item">
                        <h4>{t('rooms.sup')}</h4>
                        <h3><span></span></h3>
                            <img src="./img/rooms/ΦΩΤΟ ΓΙΑ ΔΩΜΑΤΙΟ 3 ΑΤΟΜΩΝ.jpg" alt="Rafkon Superior Appartment 2" onClick={() =>
                                handleImageClick(
                                    './img/rooms/full/ΦΩΤΟ ΓΙΑ ΔΩΜΑΤΙΟ 3 ΑΤΟΜΩΝ.jpg'
                                )
                            } />
                            <div class="ri-text">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td class="r-o">{t('rooms.size')}</td>
                                            <td>40 {t('rooms.sizedesc')}</td>
                                        </tr>

                                        <tr>
                                            <td class="r-o">{t('rooms.capacity')}</td>
                                            <td>{t('rooms.capacitydesc2')}</td>
                                        </tr>

                                        <tr>
                                            <td class="r-o">{t('rooms.beds')}</td>
                                            <td>{t('rooms.bedsdesc')}</td>
                                        </tr>

                                        <tr>
                                            <td class="r-o">{t('rooms.services')}</td>
                                            <td>{t('rooms.servicesdesc')}</td>
                                        </tr>

                                    </tbody>
                                </table>
                                <a target="_blank"
                                    rel="noopener noreferrer" href="https://www.booking.com/hotel/gr/rafkon-myrina-apartments.en-gb.html?lang=en-gb&soz=1&lang_changed=1#room_1026666602" class="primary-btn">{t('rooms.more')}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div className="room-item">
                        <h4>{t('rooms.ground')}</h4>
                        <h3><span></span></h3>
                            <img src="./img/rooms/ΦΩΤΟ ΓΙΑ ΗΜΙ ΥΠΟΓΕΙΟ.jpg" alt="Rafkon Ground Floor Appartment" onClick={() =>
                                handleImageClick(
                                    './img/rooms/full/ΦΩΤΟ ΓΙΑ ΗΜΙ ΥΠΟΓΕΙΟ.jpg'
                                )
                            } />
                            <div class="ri-text">
                                <table className="table table-responsive">
                                    <tbody>
                                        <tr>
                                            <td class="r-o">{t('rooms.size')}</td>
                                            <td>44 {t('rooms.sizedesc')}</td>
                                        </tr>

                                        <tr>
                                            <td class="r-o">{t('rooms.capacity')}</td>
                                            <td>{t('rooms.capacitydesc1')}</td>
                                        </tr>

                                        <tr>
                                            <td class="r-o">{t('rooms.beds')}</td>
                                            <td>{t('rooms.bedsdesc')}</td>
                                        </tr>

                                        <tr>
                                            <td class="r-o">{t('rooms.services')}</td>
                                            <td>{t('rooms.servicesdesc')}</td>
                                        </tr>

                                    </tbody>
                                </table>
                                <a target="_blank"
                                    rel="noopener noreferrer" href="https://www.booking.com/hotel/gr/rafkon-myrina-apartments.en-gb.html?lang=en-gb&soz=1&lang_changed=1#room_1026666603" class="primary-btn">{t('rooms.more')}</a>
                            </div>
                        </div>
                    </div>
                    {/* Add more slides as needed */}
                </div>
            </div>
            {/* Bootstrap Modal */}
            <div
                className="modal fade"
                id="imageModal"
                tabIndex="-1"
                aria-labelledby="imageModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <img
                                src={modalImage}
                                className="img-fluid"
                                alt="Full Image"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Rooms;
