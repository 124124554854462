import React from "react";
import { useTranslation } from "react-i18next";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const Gallery = () => {
    const { t } = useTranslation();

    const imageUrls = [
        {
            original: "./img/gallery/εξωτερική (6).jpg",
            thumbnail: "./img/gallery/εξωτερική (6).jpg",
        },
        {
            original: "./img/gallery/εξωτερική (8).jpg",
            thumbnail: "./img/gallery/εξωτερική (8).jpg",
        },
        {
            original: "./img/gallery/εξωτερική (3).jpg",
            thumbnail: "./img/gallery/εξωτερική (3).jpg",
        }, {
            original: "./img/gallery/apartments-3.jpg",
            thumbnail: "./img/gallery/apartments-3.jpg",
        }, {
            original: "./img/gallery/apartments-185.jpg",
            thumbnail: "./img/gallery/apartments-185.jpg",
        },
        {
            original: "./img/gallery/apartments-36.jpg",
            thumbnail: "./img/gallery/apartments-36.jpg",
        },
        {
            original: "./img/gallery/apartments-42.jpg",
            thumbnail: "./img/gallery/apartments-42.jpg",
        },
        {
            original: "./img/gallery/apartments-173.jpg",
            thumbnail: "./img/gallery/apartments-173.jpg",
        },
        {
            original: "./img/gallery/apartments-177.jpg",
            thumbnail: "./img/gallery/apartments-177.jpg",
        },
        {
            original: "./img/gallery/Apartment 4 άτομα.jpg",
            thumbnail: "./img/gallery/Apartment 4 άτομα.jpg",
        },
        {
            original: "./img/gallery/apartments-112.jpg",
            thumbnail: "./img/gallery/apartments-112.jpg",
        },
        {
            original: "./img/gallery/apartments-129.jpg",
            thumbnail: "./img/gallery/apartments-129.jpg",
        },
        {
            original: "./img/gallery/apartments-150.jpg",
            thumbnail: "./img/gallery/apartments-150.jpg",
        },
        {
            original: "./img/gallery/apartments-157.jpg",
            thumbnail: "./img/gallery/apartments-157.jpg",
        },
        {
            original: "./img/gallery/apartments-194.jpg",
            thumbnail: "./img/gallery/apartments-194.jpg",
        },
        {
            original: "./img/gallery/Κλειδαριά Ηλ.κή.jpg",
            thumbnail: "./img/gallery/Κλειδαριά Ηλ.κή.jpg",
        },
        {
            original: "./img/gallery/apartments-99.jpg",
            thumbnail: "./img/gallery/apartments-99.jpg",
        },
        {
            original: "./img/gallery/apartments-203.jpg",
            thumbnail: "./img/gallery/apartments-203.jpg",
        },
        {
            original: "./img/gallery/apartments-73.jpg",
            thumbnail: "./img/gallery/apartments-73.jpg",
        },
        {
            original: "./img/gallery/πρωινο 2.jpg",
            thumbnail: "./img/gallery/πρωινο 2.jpg",
        },
        {
            original: "./img/gallery/πρωινο 3.jpg",
            thumbnail: "./img/gallery/πρωινο 3.jpg",
        },
        {
            original: "./img/gallery/Πρωινό 1.jpg",
            thumbnail: "./img/gallery/Πρωινό 1.jpg",
        }, {
            original: "./img/gallery/Reception.jpg",
            thumbnail: "./img/gallery/Reception.jpg",
        }, {
            original: "./img/gallery/Πάγκος πρωινού 1.jpg",
            thumbnail: "./img/gallery/Πάγκος πρωινού 1.jpg",
        }, {
            original: "./img/gallery/Πάγκος πρωινού 2.jpg",
            thumbnail: "./img/gallery/Πάγκος πρωινού 2.jpg",
        },
        {
            original: "./img/gallery/apartments-55.jpg",
            thumbnail: "./img/gallery/apartments-55.jpg",
        },
        {
            original: "./img/gallery/apartments-82.jpg",
            thumbnail: "./img/gallery/apartments-82.jpg",
        },
        {
            original: "./img/gallery/apartments-89.jpg",
            thumbnail: "./img/gallery/apartments-89.jpg",
        },
        {
            original: "./img/gallery/apartments-63.jpg",
            thumbnail: "./img/gallery/apartments-63.jpg",
        },
        {
            original: "./img/gallery/Θέα Μπροστά.jpg",
            thumbnail: "./img/gallery/Θέα Μπροστά.jpg",
        },
        {
            original: "./img/gallery/Θέα Μπροστά 2.jpg",
            thumbnail: "./img/gallery/Θέα Μπροστά 2.jpg",
        },
        {
            original: "./img/gallery/Θέα Πίσω 1.jpg",
            thumbnail: "./img/gallery/Θέα Πίσω 1.jpg",
        },
        {
            original: "./img/gallery/Θέα Πίσω 2.jpg",
            thumbnail: "./img/gallery/Θέα Πίσω 2.jpg",
        },
        {
            original: "./img/gallery/Parking 2.jpg",
            thumbnail: "./img/gallery/Parking 2.jpg",
        },

    ];

    return (
        <section className="rooms-section spad">
            <div className="container">
                <ImageGallery stopPropagation items={imageUrls} />
            </div>
        </section>
    );
};

export default Gallery;
